// import { messageStatusOption } from '@/utils/constant/const.js'
// import { getOptionsItemLabel } from '@/utils/common'

export const contentTableConfig = {
  title: '',
  propList: [
    {
      prop: 'title',
      label: 'profile.title'
    },
    {
      prop: 'userNames',
      label: 'profile.receiver'
    },
    {
      prop: 'createdTime',
      slotName: 'formatZeroTime',
      label: 'common.app-creation-time',
      width: 160
    },
    {
      label: 'common.app-operation',
      width: 200,
      slotName: 'handler'
    }
  ],
  showIndexColumn: true
}
